import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './feedback_template.module.scss';
import Reviews from '../modules/SharedModules/SliderBlock/Reviews/Reviews';

export interface IndexProps {
  pageContext: {
    feedback: {
      Title: string;
      TitleBackgroundColor: string;
      Description: string;
      TopPhotos340x400: object[];
      BottomPhotos340x400: object[];
    };
  };
}

const FeedbackPage: React.FC<IndexProps> = ({ pageContext }) => {
  const { t } = useTranslation();

  const { feedback } = pageContext;

  return (
    <div className={css.container}>
      <div className={css.title} style={{ backgroundColor: feedback.TitleBackgroundColor }}>
        {feedback.Title}
      </div>
      <div className={css.slider}>
        <div className={css.title}>{t('shell:feedback.results')}</div>
        <Reviews arr={feedback.TopPhotos340x400} />
      </div>
      <div className={css.description}>{feedback.Description}</div>
      <div className={css.slider}>
        <div className={css.title}>{t('shell:feedback.reviews')}</div>
        <Reviews arr={feedback.BottomPhotos340x400} />
      </div>
    </div>
  );
};

export default FeedbackPage;
