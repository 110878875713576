import React, { useState, useEffect } from 'react';
import css from './style.module.scss';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import arrowLeftIcon from '../../../../assets/icons/arrow-left.svg';
import { useWindowSize } from '../../../../lib/hooks/useWindowSize';

interface ReviewsProps {
  arr: object[];
}

const Reviews: React.FC<ReviewsProps> = ({ arr }) => {
  const [sliderWidth, setSliderWidth] = useState(1080);

  const screenWidth = useWindowSize();

  const [images, setImages]: any[] = useState([]);

  useEffect(() => {
    setImages([...arr, ...arr]);
  }, []);

  useEffect(() => {
    const num = Math.floor((screenWidth.width - 220) / 360) * 360;

    if (num > 1080) {
      setSliderWidth(1080);
    }
    if (num < 1080 && num > 0) {
      setSliderWidth(num);
    }
    if (num === 0) {
      setSliderWidth(360);
    }
  }, [screenWidth.width]);

  const handelClick = (side: string) => {
    const newArr = [...images];

    if (side === 'left') {
      newArr.unshift(images[images.length - 1]);
      newArr.splice(-1, 1);

      setImages([...newArr]);
    }
    if (side === 'right') {
      newArr.push(images[0]);
      newArr.splice(0, 1);

      setImages([...newArr]);
    }
  };

  return (
    <>
      <div className={css.container}>
        <div onClick={() => handelClick('left')} className={css.arrow}>
          <img className={css.img} src={arrowLeftIcon} alt="arrow-left" />
        </div>
        <div style={{ width: sliderWidth }} className={css.slider}>
          {images.map((item: any, index: number) => {
            return (
              <div key={`${index}SLIDER`} className={css.imgContainer}>
                <img className={css.img} src={process.env.STRAPI_API_URL + item.url} alt={process.env.STRAPI_API_URL + item.name} />
              </div>
            );
          })}
        </div>
        <div className={css.arrowSmall} onClick={() => handelClick('right')} className={css.arrow}>
          <img className={css.img} src={arrowRightIcon} alt="arrow-right" />
        </div>
      </div>
      <div className={css.bottomContainer}>
        <div className={css.arrowSmall} onClick={() => handelClick('left')}>
          <img className={css.img} src={arrowLeftIcon} alt="arrow-left" />
        </div>
        <div className={css.arrowSmall} onClick={() => handelClick('right')}>
          <img className={css.img} src={arrowRightIcon} alt="arrow-right" />
        </div>
      </div>
    </>
  );
};

export default Reviews;
